import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./Auth";

const ProtectedRoutes = () => {
  const { token, decode } = useAuth();
  const today = new Date();
  const expired = decode?.exp;

  if (!token || (expired && today > new Date(expired * 1000))) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
