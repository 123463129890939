import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  cheshidByMehsulID,
  getCheshidFakturaInterface,
} from "../interfaces/interfaces";

const getProdinitialState: getCheshidFakturaInterface = {
  cheshidsFaktura: [],
  status: "idle",
};

export const getCheshidFakturaListAsync = createAsyncThunk<
  Array<cheshidByMehsulID>,
  number
>(
  "getCheshidFakturaListAsync/getCheshidFakturaListSlicer",
  async (mehsulID) => {
    try {
      const response = await API.get(`/mehsul_to_cheshid/${mehsulID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getCheshidFakturaListSlice = createSlice({
  name: "getCheshidFakturaListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCheshidFakturaListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getCheshidFakturaListAsync.fulfilled,
        (state, action: PayloadAction<Array<cheshidByMehsulID>>) => {
          state.cheshidsFaktura = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getCheshidFakturaListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const CheshidListFaktura = (state: RootState) =>
  state.cheshidFakturaList.cheshidsFaktura;
export default getCheshidFakturaListSlice.reducer;
