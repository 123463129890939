import { TextField } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { searchItemsFakturaInterface } from "../../../interfaces/interfaces";
import { DateRangePicker, SelectPicker } from "rsuite";
import IMPORT from "../../../images/IMPORT.png";
import { useAppDispatch } from "../../../store/hooks";
import format from "date-fns/format";
import { getCheshidFakturaListAsync } from "../../../generalUsesReducers/getCheshidFakturaSlice";
import { getKodFakturaListAsync } from "../../../generalUsesReducers/getKodFakturaSlice";

const SearchBar = ({
  searchItems,
  setSearchItems,
  setPage,
}: {
  searchItems: searchItemsFakturaInterface;
  setSearchItems: Function;
  setPage: Function;
}) => {
  const dispatch = useAppDispatch();
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { cheshidsFaktura } = useAppSelector(
    (state) => state.cheshidFakturaList
  );
  const { kodFaktura } = useAppSelector((state) => state.kodFakturaList);

  return (
    <>
      <div style={{ marginTop: "4em" }}>
        <section className="tableAlish-select-section">
          <img className="import-logo" src={IMPORT} alt="import-logo"></img>
          <div>
            <SelectPicker
              style={{ width: 180, marginLeft: "1em" }}
              data={mehsullar}
              size="lg"
              labelKey="mehsul"
              valueKey="id"
              placeholder="Məhsul"
              onSelect={(event: any) => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: event,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidFakturaListAsync(event));
              }}
              onClean={() => {
                setSearchItems({
                  ...searchItems,
                  mehsulID: 0,
                  skip: 1,
                });
                setPage(1);
                dispatch(getCheshidFakturaListAsync(0));
              }}
            />
          </div>

          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={cheshidsFaktura}
            size="lg"
            labelKey="cheshid"
            valueKey="id"
            placeholder="Çeşid"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                cheshidID: event,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodFakturaListAsync(event));
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                cheshidID: 0,
                skip: 1,
              });
              setPage(1);
              dispatch(getKodFakturaListAsync(0));
            }}
          />
          <SelectPicker
            virtualized
            style={{ width: 180, marginLeft: "1em" }}
            data={kodFaktura}
            size="lg"
            labelKey="kod"
            valueKey="id"
            placeholder="Kod"
            onSelect={(event: any) => {
              setSearchItems({
                ...searchItems,
                kodID: event,
                skip: 1,
              });
              setPage(1);
            }}
            onClean={() => {
              setSearchItems({
                ...searchItems,
                kodID: 0,
                skip: 1,
              });
              setPage(1);
            }}
          />

          <TextField
            style={{ margin: "0 1em", width: "12em", background: "white" }}
            label="faktura"
            variant="outlined"
            type="search"
            size="small"
            value={searchItems.faktura}
            onChange={(e) => {
              setSearchItems({
                ...searchItems,
                faktura: e.target.value,
                skip: 1,
              });
              setPage(1);
            }}
          />

          <DateRangePicker
            appearance="default"
            size="lg"
            placeholder={`${searchItems?.date1} ~ ${searchItems?.date2}`}
            placement="bottomEnd"
            cleanable={false}
            menuStyle={{ width: 540 }}
            onChange={(value: any) => {
              setPage(1);
              if (value !== null) {
                setSearchItems({
                  ...searchItems,
                  date1: format(value[0], "yyyy-MM-dd"),
                  date2: format(
                    value[1].setDate(value[1].getDate()),
                    "yyyy-MM-dd"
                  ),
                  skip: 1,
                });
              } else {
                setSearchItems((prev: any) => {
                  const { date1, date2, ...rest } = prev;
                  return rest;
                });
              }
            }}
          />
        </section>
      </div>
    </>
  );
};
export default SearchBar;
