import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  getKodFakturaInterface,
  kodByCheshidID,
} from "../interfaces/interfaces";

const getProdinitialState: getKodFakturaInterface = {
  kodFaktura: [],
  status: "idle",
};

export const getKodFakturaListAsync = createAsyncThunk<
  Array<kodByCheshidID>,
  number
>("getKodFakturaListAsync/getKodFakturaListSlicer", async (kodID) => {
  try {
    const response = await API.get(`/cheshid_to_kod/${kodID}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getKodFakturaListSlice = createSlice({
  name: "getKodFakturaListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getKodFakturaListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getKodFakturaListAsync.fulfilled,
        (state, action: PayloadAction<Array<kodByCheshidID>>) => {
          state.kodFaktura = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getKodFakturaListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const KodFakturaList = (state: RootState) =>
  state.kodFakturaList.kodFaktura;

export default getKodFakturaListSlice.reducer;
