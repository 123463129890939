import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import {
  getCheshidInterface,
  cheshidByMehsulID,
} from "../interfaces/interfaces";

const getProdinitialState: getCheshidInterface = {
  cheshids: [],
  status: "idle",
};

export const getCheshidTarixceListAsync = createAsyncThunk<
  Array<cheshidByMehsulID>,
  number
>(
  "getCheshidTarixceListAsync/getCheshidTarixceListSlicer",
  async (mehsulID) => {
    try {
      const response = await API.get(`/mehsul_to_cheshid/${mehsulID}`);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getCheshidTarixceListSlice = createSlice({
  name: "getCheshidTarixceListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCheshidTarixceListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getCheshidTarixceListAsync.fulfilled,
        (state, action: PayloadAction<Array<cheshidByMehsulID>>) => {
          state.cheshids = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getCheshidTarixceListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});
export const CheshidTarixceList = (state: RootState) =>
  state.cheshidTarixceList.cheshids;
export default getCheshidTarixceListSlice.reducer;
