import React, { useState, useEffect } from "react";
import TableFaktura from "./components/TableFaktura";
import SearchBar from "./components/SearchBarFaktura";
import { getFakturaListAsync } from "../../generalUsesReducers/getFakturaSlice";
import { controlLoad } from "../../generalUsesReducers/Loading";
import { useAppDispatch } from "../../store/hooks";

const Faktura = () => {
  const dispatch = useAppDispatch();
  const date = new Date();
  const date2 = new Date();
  date2.setDate(date2.getDate() + 1);
  date.setMonth(date.getMonth() - 1);

  const myDate1 =
    date.getFullYear() +
    "-" +
    ("0" + Number(date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  const myDate2 =
    date2.getFullYear() +
    "-" +
    ("0" + (date2.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date2.getDate()).slice(-2);
  const [page, setPage] = useState(1);

  const [searchItems, setSearchItems] = useState({
    stockID: 0,
    mehsulID: 0,
    cheshidID: 0,
    warehouseID: 0,
    kodID: 0,
    faktura: "",
    take: 10,
    skip: 1,
    date1: myDate1,
    date2: myDate2,
  });

  useEffect(() => {
    const initApp = async () => {
      dispatch(controlLoad(false));
      try {
        dispatch(controlLoad(true));
        await dispatch(getFakturaListAsync(searchItems));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(controlLoad(false));
      }
    };
    initApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItems]);
  return (
    <div className="table-faktura">
      <SearchBar
        searchItems={searchItems}
        setSearchItems={setSearchItems}
        setPage={setPage}
      />
      <TableFaktura
        searchItems={searchItems}
        setSearchItems={setSearchItems}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default Faktura;
