import React from "react";
import { TextField } from "@mui/material";
import { useAppSelector } from "../../../../store/hooks";
import { searchParametersInterface } from "../../../../interfaces/interfaces";
import { SelectPicker } from "rsuite";
import STOCK from "../../../../images/STOCK.png";
import { useAppDispatch } from "../../../../store/hooks";
import { getCheshidListAsync } from "../../../../generalUsesReducers/getCheshidSlice";
import { getKodListAsync } from "../../../../generalUsesReducers/getKodSlice";

const TableSearche = ({
  searchParameters,
  setSearchParameters,
  setPage,
}: {
  searchParameters: searchParametersInterface;
  setSearchParameters: Function;
  setPage: Function;
}) => {
  const dispatch = useAppDispatch();
  const { warehouse_ } = useAppSelector((state) => state.warehouseList);
  const { cheshids } = useAppSelector((state) => state.cheshidList);
  const { mehsullar } = useAppSelector((state) => state.mehsulList);
  const { kod_ } = useAppSelector((state) => state.kodList);
  return (
    <div className="tableAlish-select-section">
      <section className="text-center">
        <img className="stock-logo" alt="stock-logo" src={STOCK}></img>
      </section>
      <SelectPicker
        style={{ width: 200, marginLeft: "1em" }}
        data={mehsullar}
        size="lg"
        labelKey="mehsul"
        valueKey="id"
        placeholder="Məhsul"
        onSelect={(event: any) => {
          setSearchParameters({
            ...searchParameters,
            mehsulID: event,
            skip: 1,
          });
          setPage(1);
          dispatch(getCheshidListAsync(event));
        }}
        onClean={() => {
          setSearchParameters({ ...searchParameters, mehsulID: 0, skip: 1 });
          setPage(1);
          dispatch(getCheshidListAsync(0));
        }}
      />

      <SelectPicker
        virtualized
        style={{ width: 200, marginLeft: "1em" }}
        data={cheshids}
        size="lg"
        labelKey="cheshid"
        valueKey="id"
        placeholder="Çeşid"
        onSelect={(event: any) => {
          setSearchParameters({
            ...searchParameters,
            cheshidID: event,
            skip: 1,
          });
          setPage(1);
          dispatch(getKodListAsync(event));
        }}
        onClean={() => {
          setSearchParameters({ ...searchParameters, cheshidID: 0, skip: 1 });
          setPage(1);
          dispatch(getKodListAsync(0));
        }}
      />
      <SelectPicker
        virtualized
        style={{ width: 200, marginLeft: "1em" }}
        data={kod_}
        size="lg"
        labelKey="kod"
        valueKey="id"
        placeholder="Kod"
        onSelect={(event: any) => {
          setSearchParameters({
            ...searchParameters,
            kodID: event,
            skip: 1,
          });
          setPage(1);
        }}
        onClean={() => {
          setSearchParameters({ ...searchParameters, kodID: 0, skip: 1 });
          setPage(1);
        }}
      />

      <SelectPicker
        style={{ width: 200, marginLeft: "1em" }}
        data={warehouse_}
        size="lg"
        labelKey="warehouse"
        valueKey="id"
        placeholder="Mağaza"
        onSelect={(event: any) => {
          setSearchParameters({
            ...searchParameters,
            warehouseID: event,
            skip: 1,
          });
          setPage(1);
        }}
        onClean={() => {
          setSearchParameters({
            ...searchParameters,
            warehouseID: 0,
            skip: 1,
          });
          setPage(1);
        }}
      />

      <div className="mobile-barkod">
        <TextField
          className="textfield"
          style={{ margin: "0 1em", width: "15em", background: "white" }}
          label="barkod"
          type="search"
          size="small"
          value={searchParameters.barkod}
          onChange={(e: any) => {
            setSearchParameters({
              ...searchParameters,
              barkod: e.target.value,
              skip: 1,
            });
            setPage(1);
          }}
        />
      </div>
    </div>
  );
};
export default TableSearche;
