import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { API } from "../baseUrl/baseUrl";
import { getKodInterface, kodByCheshidID } from "../interfaces/interfaces";

const getProdinitialState: getKodInterface = {
  kod_: [],
  status: "idle",
};

export const getKodTarixceListAsync = createAsyncThunk<
  Array<kodByCheshidID>,
  number
>("getKodTarixceListAsync/getKodTarixceListSlicer", async (kodID) => {
  try {
    const response = await API.get(`/cheshid_to_kod/${kodID}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getKodTarixceListSlice = createSlice({
  name: "getKodTarixceListSlicer",
  initialState: getProdinitialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getKodTarixceListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getKodTarixceListAsync.fulfilled,
        (state, action: PayloadAction<Array<kodByCheshidID>>) => {
          state.kod_ = action.payload;
          state.status = "idle";
        }
      )
      .addCase(getKodTarixceListAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const KodTarixceList = (state: RootState) => state.kodTarixceList.kod_;

export default getKodTarixceListSlice.reducer;
