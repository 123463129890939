import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import mehsulReducer from "../generalUsesReducers/mehsulSlice";
import cheshidReducer from "../generalUsesReducers/getCheshidSlice";
import kodReducer from "../generalUsesReducers/getKodSlice";
import olchuReducer from "../generalUsesReducers/getOlchuSlice";
import warehouseReducer from "../generalUsesReducers/getWarehouseSlice";
import productReducer from "../generalUsesReducers/stockSlice";
import permissionsToRoleReducer from "../generalUsesReducers/permissionToRoleSlicer";
import roleReducer from "../generalUsesReducers/getRoleSlicer";
import loadReducer from "../generalUsesReducers/Loading";
import permissionsReducer from "../generalUsesReducers/listPermissionSlicer";
import fakturaReducer from "../generalUsesReducers/getFakturaSlice";
import cheshidFakturaReducer from "../generalUsesReducers/getCheshidFakturaSlice";
import kodFakturaReducer from "../generalUsesReducers/getKodFakturaSlice";
import cheshidTarixceReducer from "../generalUsesReducers/getCheshidTarixceSlice";
import kodTarixceReducer from "../generalUsesReducers/getKodTarixceSlice";

export const store = configureStore({
  reducer: {
    productList: productReducer,
    mehsulList: mehsulReducer,
    olchuList: olchuReducer,
    cheshidList: cheshidReducer,
    cheshidTarixceList: cheshidTarixceReducer,
    cheshidFakturaList: cheshidFakturaReducer,
    kodList: kodReducer,
    kodTarixceList: kodTarixceReducer,
    kodFakturaList: kodFakturaReducer,
    warehouseList: warehouseReducer,
    fakturaList: fakturaReducer,
    permissionsToRoleList: permissionsToRoleReducer,
    permissionsList: permissionsReducer,
    roleList: roleReducer,
    loading: loadReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
