import React, { useEffect } from "react";
import { Kassa } from "../../../interfaces/interfaces";

const KassaComponent = ({
  setTotalPrice,
  kassa,
  setKassa,
  endirim,
  setEndirim,
  setQaliqPul,
  setOdenildi,
  setWarning,
  totalPrice,
}: {
  setTotalPrice: Function;
  kassa: Array<Kassa>;
  setKassa: Function;
  endirim: number;
  setEndirim: Function;
  setQaliqPul: Function;
  setOdenildi: Function;
  setWarning: Function;
  totalPrice: number;
}) => {
  const changeQuantityProduct = (e: any) => {
    setKassa(
      kassa.map((item: any) => {
        if (item.id === parseFloat(e.target.name)) {
          const sayi = isNaN(e.target.valueAsNumber)
            ? ""
            : e.target.valueAsNumber;
          const meblegi =
            (item.qiymet - item.kampaniya_endirimi) * e.target.valueAsNumber;
          console.log(totalPrice);
          return { ...item, say: sayi, mebleg: meblegi };
        } else {
          return item;
        }
      })
    );
    setOdenildi(0);
    setQaliqPul(0);
  };

  useEffect(() => {
    if (kassa.length > 0) {
      setTotalPrice(
        kassa.reduce(
          (accumulator: number, item: Kassa) => accumulator + item.mebleg,
          0
        ) - endirim
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kassa, endirim]);

  const deleteProduct = (id: number) => {
    setKassa(kassa.filter((item: any) => item.id !== id));
    if (kassa.length === 1) {
      setTotalPrice(0);
      setEndirim(0);
      setQaliqPul(0);
      setOdenildi(0);
      setWarning(false);
    }
  };

  return (
    <>
      <table className="tablo-test">
        <thead>
          <tr>
            <td style={{ width: "1em" }}>S/s</td>
            <td>Məhsul</td>
            <td>Çeşid</td>
            <td>Kod</td>
            <td style={{ width: "4em" }}>Qiymət</td>
            <td style={{ width: "4em" }}>Say</td>
            <td style={{ width: "8em" }}>Kampaniya endirimi</td>
            <td style={{ width: "6em" }}>Məbləğ</td>
            <td style={{ width: "5em" }}>#</td>
          </tr>
        </thead>
        <tbody>
          {kassa.length >= 1 &&
            kassa.map((item: any, index: number) => (
              <tr key={item.id}>
                <td style={{ width: "1em" }}>{index + 1}</td>
                <td>{item.mehsul_.mehsul}</td>
                <td>{item.cheshid_.cheshid}</td>
                <td>{item.kod_.kod}</td>
                <td style={{ width: "4em" }}> {item.qiymet}</td>
                <td style={{ width: "4em" }}>
                  <input
                    className="saleInput"
                    type="number"
                    name={item.id}
                    value={item.say}
                    onChange={changeQuantityProduct}
                  />
                </td>
                <td style={{ width: "8em" }}>{item.kampaniya_endirimi}</td>
                <td style={{ width: "6em" }}>
                  {item.mebleg
                    ? Math.round(item.mebleg * 100) / 100
                    : item.qiymet - item.kampaniya_endirimi}
                </td>
                <td style={{ width: "5em" }}>
                  <button onClick={() => deleteProduct(item.id)}>
                    Ləğv et
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default KassaComponent;
