import React, { useState } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { getProdListAsync } from "../../../../generalUsesReducers/stockSlice";
import { API } from "../../../../baseUrl/baseUrl";
import { HiOutlineQrCode } from "react-icons/hi2";
import { MdOutlinePriceChange } from "react-icons/md";
import { Alert } from "../../../../alert/alert";
import SearchSelect from "./SearchSelect";
import MarkaPopUp from "./MarkaPopUp";
import {
  productInterface,
  searchParametersInterface,
} from "../../../../interfaces/interfaces";
import { getCheshidListAsync } from "../../../../generalUsesReducers/getCheshidSlice";
import { getKodListAsync } from "../../../../generalUsesReducers/getKodSlice";
import { getOlchuListAsync } from "../../../../generalUsesReducers/getOlchuSlice";

const CreateProduct = ({
  showContainer,
  search,
}: {
  showContainer: boolean;
  search: searchParametersInterface;
}) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [product, setProduct] = useState<productInterface>({
    mehsulID: 0,
    cheshidID: 0,
    kodID: 0,
    olchuID: 0,
    warehouseID: 0,
    barkod: "",
    qiymet: Number(),
    kampaniya_endirimi: 0,
  });

  const addNewProduct = async (e: any) => {
    e.preventDefault();
    setLoading(false);
    try {
      setLoading(true);
      const response = await API.post(`/stock`, product);
      setProduct({
        ...product,
        barkod: "",
        qiymet: Number(),
        kampaniya_endirimi: 0,
      });
      Alert({
        type: "success",
        title: response?.data,
        placement: "bottomCenter",
        duration: 2000,
      });
    } catch (error: any) {
      Alert({
        type: "error",
        title: error.response.data,
        placement: "topCenter",
        duration: 5000,
      });
      console.log(error);
    }
    setTimeout(() => {
      dispatch(getProdListAsync(search));
    }, 100);
    setLoading(false);
  };

  // Modal
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => {
    dispatch(getCheshidListAsync(0));
    dispatch(getKodListAsync(0));
    dispatch(getOlchuListAsync(0));
    setOpen(true);
  };

  return (
    <div
      className={
        showContainer ? "container_alTable close-div" : "container_alTable"
      }
    >
      <div className="create-marka-div">
        <button onClick={() => handleOpen()} className="create-marka">
          Marka yaratmaq
        </button>
      </div>
      <MarkaPopUp open={open} setOpen={setOpen} />
      <p className="p">Satış mərkəzinə yeni məhsul əlavə edin:</p>
      <section className="alishTable-form">
        <form id="form-alish" className="form" onSubmit={addNewProduct}>
          <SearchSelect product={product} setProduct={setProduct} />
          <div className="alish-input-div">
            <input
              autoComplete="off"
              name="barkod"
              type="text"
              id="barkod"
              value={product.barkod}
              onChange={(e) => {
                setProduct({
                  ...product,
                  barkod: e.target.value,
                });
              }}
              required
            />
            <label htmlFor="barkod" className="slide-label">
              Barkod<span style={{ color: "red" }}>*</span>
            </label>
            <HiOutlineQrCode className="icons-for-inputs" />
          </div>
          <div className="alish-input-div">
            <input
              autoComplete="off"
              type="number"
              id="qiymet"
              name="qiymet"
              onChange={(e) => {
                setProduct({
                  ...product,
                  qiymet: e.target.valueAsNumber,
                });
              }}
              value={product.qiymet === 0 ? "" : product.qiymet}
              required
            />
            <label htmlFor="qiymet" className="slide-label">
              Satış qiyməti<span style={{ color: "red" }}>*</span>
            </label>
            <MdOutlinePriceChange className="icons-for-inputs" />
          </div>
          <div className="alish-input-div">
            <input
              autoComplete="off"
              name="kampaniya_endirimi"
              type="number"
              id="kampaniya_endirimi"
              onChange={(e) => {
                setProduct({
                  ...product,
                  kampaniya_endirimi: e.target.valueAsNumber,
                });
              }}
              value={product.kampaniya_endirimi}
              pattern="[0-9.]{1,}"
            />
            <label htmlFor="kampaniya_endirimi" className="slide-label">
              Kampaniya endirimi
            </label>
            <MdOutlinePriceChange className="icons-for-inputs" />
          </div>

          <div>
            <button type="submit" className="submit-btn" disabled={loading}>
              Təsdiq edin
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default CreateProduct;
